import { useEffect } from 'react';
import { languageList } from '@/constants/languages';
import i18n from '@/i18n';
import { MenuListSelection } from './MenuListSelection';

export const LanguageSelection = ({ limitLanguages }: { limitLanguages: Array<string> }) => {
  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    window.localStorage.setItem('lang', lang);
  };

  const languages = (
    limitLanguages ? languageList.filter(({ key }) => limitLanguages.includes(key)) : languageList
  ).map(({ key, label, flagPath }) => ({ label, value: key, icon: flagPath }));

  useEffect(() => {
    const languages = window.navigator.languages
      .map((lang) => lang.split('-')[0])
      .map((lang) => (lang === 'ba' ? 'hr' : lang))
      .map((lang) => (lang === 'cnr' ? 'sr' : lang))
      .filter((lang) => limitLanguages.includes(lang));
    const newLanguage = window.localStorage.getItem('lang') || languages[0] || 'en';

    if (newLanguage !== i18n.language) {
      i18n.changeLanguage(newLanguage);
    }
  }, [limitLanguages]);

  if (limitLanguages && languages.length < 2) {
    return null;
  }

  const activeLanguage =
    languageList.find(({ key }) => key === i18n.language) ||
    languageList.find(({ key }) => key === 'en') ||
    languageList[0];

  console.log(languageList, activeLanguage, i18n.language);

  return (
    <MenuListSelection
      options={languageList}
      value={activeLanguage.key}
      defaultValue={activeLanguage}
      label={'common.language'}
      onChange={changeLanguage}
    />
  );
};
